import { createGlobalStyle } from "styled-components"

import { BREAKPOINT_MOBILE } from "./breakpoints"

import bebas from "../fonts/BebasNeue-Regular.ttf"
import openSans from "../fonts/OpenSans-Regular.ttf"
import openSansBold from "../fonts/OpenSans-Bold.ttf"
import openSansLightItalic from "../fonts/OpenSans-LightItalic.ttf"

const Typography = createGlobalStyle`
  @font-face {
    font-family: BebasNeue;
    src: url(${bebas})
  }
  @font-face {
    font-family: OpenSans;
    src: url(${openSans});
  }
  @font-face {
    font-family: OpenSansBold;
    src: url(${openSansBold});
  }
  @font-face {
    font-family: OpenSansLightItalic;
    src: url(${openSansLightItalic});
  }

  :root {
    --fallback-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  /* Base font sizing */
  html {
    font-family: OpenSans, var(--fallback-fonts);
    font-size: 10px;
    color: var(--white);

    @media (max-width: ${BREAKPOINT_MOBILE}) {
      font-size: 8px;
    }
  }

  body {
    font-size: 2rem;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: BebasNeue, var(--fallback-fonts);
    font-weight: normal;
    margin: 0;
  }

  a {
    color: var(--white);
    text-decoration-color: var(--red);
    /* Chrome renders this weirdly with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

  .bold {
    font-family: OpenSansBold, var(--fallback-fonts);
  }

  .light-italic {
    font-family: OpenSansLightItalic, var(--fallback-fonts);
  }

  .center {
    text-align: center;
  }

  .dark-on-light, .dark-on-white {
    color: var(--blue);

    a {
      color: var(--blue);
    }
  }
`

export default Typography
