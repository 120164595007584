import React from "react"
import PropType from "prop-types"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby';

export default function SEO({ children = null }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          image
          siteUrl
          title
        }
      }
    }
  `);

  return (
    <Helmet>
      <html lang="en" />
      <title>{`Fitzbuild`}</title>
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="image" content={site.siteMetadata.image} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:title" content={site.siteMetadata.title} key="ogtitle" />
      <meta property="og:site_name" content={site.siteMetadata.title} key="ogsitename" />
      <meta property="og:description" content={site.siteMetadata.description} key="ogdescription" />
      <meta property="og:image" content={site.siteMetadata.image} />
      {children}
    </Helmet>
  )
}

SEO.propTypes = {
  children: PropType.shape(),
}
