import React from "react"
import PropTypes from "prop-types"
import "normalize.css"

import CookieConsentBar from "../components/CookieConsentBar"
import GlobalStyles from "../assets/css/Global"
/* eslint-disable react/jsx-pascal-case */
import SEO from "../components/SEO"
import Typography from "../assets/css/Typography"

export default function Layout({ children }) {
  return (
    <div>
      <GlobalStyles />
      <Typography />
      <SEO />
      {children}
      <CookieConsentBar />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
}
