import React from "react"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "@spencerwhyte/gatsby-plugin-gdpr-cookies"
import CookieConsent from 'react-cookie-consent'

export default function CookieConsentBar() {
  const location = useLocation()
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      enableDeclineButton
      flipButtons
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      declineButtonStyle={{
        "backgroundColor": "#666",
        "marginLeft": "0"
      }}
      onAccept={() => initializeAndTrack(location)}
    >
      This site uses cookies to improve your experience. We only use this data to improve our site and do not track you anywhere else.
    </CookieConsent >
  )
}
