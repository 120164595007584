import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
:root {
  --blue: #181D35;
  --red: #C64738;
  --darkred: #AD2E1F;
  --black: #2E2E2E;
  --white: #fff;
  --grey: #efefef;

  --sectionPadding: 19.2rem;
}

html {
  background-color: var(--blue);
}

body {
  overflow-x: hidden;
}

/* Styling for initial images */
.gatsby-image-wrapper img[src*=base64\\,] {
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
}

img {
  max-width: 100%;
}

/* Global section styling */

.hide {
  display: none;
}
`

export default GlobalStyles
